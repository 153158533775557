import React from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'

function Navbar(props) {

    const handleClick = () => {
        window.scrollTo({
            top: 5520,  // Specify the vertical position (y-axis)
            left: 0,   // You can specify horizontal position (x-axis) if needed
            behavior: 'smooth'  // Optional for smooth scrolling
        });

        // props.targetRef.current.scrollIntoView({ behavior: 'smooth' });

        // Ensure scroll position is adjusted after a delay if needed
        // setTimeout(() => {
        //     props.targetRef.current.scrollIntoView({});
        // }, 600); // Adjust this delay as per content loading time
    };

    return (
        <div className="navbar">

            <div className="logo">
                <Link to="/">
                    <img src="navbar_assets/tevah-navbar.png" alt="tevah" />
                </Link>
            </div>

            <div className="navigation">
                <ul>
                    {/* <li className={props.selected == 'services' ? 'navigation-animate' : ''}><Link to='/services'>{props.selected == 'services' ? '[ Services ]' : 'Services'}</Link></li>
                    <li><Link to='/work'>{props.selected == 'work' ? '[ Work ]' : 'Work'}</Link></li>
                    <li><Link to='/about'>{props.selected == 'about' ? '[ About ]' : 'About'}</Link></li> */}

                    <li className={props.selected == 'services' ? 'navigation-animate' : ''}><Link to='/'>{props.selected == 'services' ? '[ Services ]' : 'Services'}</Link></li>
                    <li><Link to='/'>{props.selected == 'work' ? '[ Work ]' : 'Work'}</Link></li>
                    <li><Link to='/'>{props.selected == 'about' ? '[ About ]' : 'About'}</Link></li>
                </ul>
                <div onClick={handleClick} className="contact">Contact</div>
            </div>
        </div>
    )
}

export default Navbar
