import React from 'react'
import './Services.css'
import '../Homepage/Homepage.css'
import Navbar from '../Navbar/navbar'
import BottomAbout from '../BottomAbout/bottomAbout'
import { cn } from "../lib/utils";
import { DotPattern } from "../components/ui/dot-pattern";
import TextRevealByWord from '../components/ui/text-reveal'


function Services() {
    return (
        <>

            {/* ////////// INTRODUCTION ////////// */}


            <div className="foreground">

                <Navbar selected={'services'}></Navbar>

                <div className="webpage-align">

                    <div class="services-intro">

                        <div className="services-left">
                            <div className="services-intro-title">
                                Crafting Experiences That Evoke Emotions
                            </div>
                            <div className="services-intro-subtitle">
                                Lorem ipsum is placeholder text commonly used in the graphic, print,
                                and publishing industries for previewing layouts and visual mockups.
                            </div>
                            <button class="services-intro-outlined-button">Let's Connect</button>
                        </div>

                        <div className="services-right">
                            <div className="services-intro-top-bubble">
                                <img src="services_assets/images/services-intro-topright.png" alt="" />
                            </div>
                            <div className="services-intro-center-image">
                                <img src="services_assets/images/services-intro-image.png" alt="" />
                            </div>
                            <div className="services-intro-bottom-bubble">
                                <img src="services_assets/images/services-intro-bottomleft.png" alt="" />
                            </div>
                        </div>
                    </div>


                    {/* ////////// MAGIC UI ////////// */}

                    <div className="relative flex h-[1200px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background md:shadow-xl">
                        <p className="z-10 whitespace-pre-wrap text-center text-5xl font-medium tracking-tighter text-black dark:text-white">

                        </p>
                        <DotPattern
                            className={cn(
                                "[mask-image:radial-gradient(600px_circle_at_center,white,transparent)]",
                            )}
                        />
                    </div>

                    <div className="text-reveal">
                        <div className="z-2 flex min-h-64 items-center justify-center rounded-lg border ">
                            <TextRevealByWord text="Magic UI will change the way you design." />
                        </div>
                    </div>



                    <div className="services-black">
                        <div className="services-black-left-circle"></div>
                        <div className="services-black-center"><span>LOREM IPS</span>UM IS PLACEHOLDER TEXT COMMONLY USED IN THE GRAPHIC</div>
                        <div className="services-black-right-circle"></div>
                    </div>


                    {/* /////////////////////////// NAVIGATION TAB /////////////////////////// */}


                    <div className="services-navigation-tab">

                        <div className="services-navigation-tab-head">
                            <div className="services-experience-design">

                            </div>
                            <div className="services-identity-branding"></div>
                            <div className="services-digital-marketing"></div>
                            <div className="services-technology-stacks"></div>
                        </div>

                        <div className="services-navigation-tab-body"></div>
                    </div>

                    <div className="services-design-process"></div>

                    <BottomAbout></BottomAbout>
                </div>
            </div>
        </>
    )
}

export default Services