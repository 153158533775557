import React from 'react'
import './bottomContact.css'
import FadeInDivOnScroll from '../GeneralComponent/fadeInDivScroll'

function BottomContact() {
    return (
        <div className="project-contact">

            <div className="achieve-bottom-lights">
                <img src="homepage_assets/images/light1.png" alt="" className="achieve-bottom-light1" />
                <img src="homepage_assets/images/light2.png" alt="" className="achieve-bottom-light2" />
            </div>

            <div className="project-contact-without-light">

                <div className="contact-left">

                    <FadeInDivOnScroll class='contact-gif-animation'>
                        <img src="homepage_assets/gif/out_of_the_box.gif" alt="out of the box" className="out-of-the-box" />
                    </FadeInDivOnScroll >

                    <FadeInDivOnScroll class='contact-head-animation'>
                        <div className="contact-head">Have a project in mind?</div>
                    </FadeInDivOnScroll >

                    <FadeInDivOnScroll class='contact-desc-animation'>
                        <div className="contact-desc">At <span>TEVAH</span>, we don't just create designs; we craft experiences that resonate. Join us on the journey of transforming ideas into impactful realities. Let's build something extraordinary together.</div>
                    </FadeInDivOnScroll >

                    <div className="contact-box">

                        <FadeInDivOnScroll class='contact-gif-animation'>
                            <div className="contact-email">
                                <div className="contact-email-head">
                                    <img src="homepage_assets/images/email.png" alt="" className="email-icon" />
                                    <span>Email</span>
                                </div>
                                <div className="contact-email-body">info@tevahdesign.com</div>
                            </div>
                        </FadeInDivOnScroll >

                        <FadeInDivOnScroll class='contact-gif-animation'>
                            <div className="contact-phone">
                                <div className="contact-phone-head">
                                    <img src="homepage_assets/images/phone.png" alt="" className="phone-icon" />
                                    <span>Phone</span>
                                </div>
                                <div className="contact-phone-body">XXX XXX XXXX</div>
                            </div>
                        </FadeInDivOnScroll >
                    </div>
                </div>

                <div className="contact-right">

                    <div className="contact-details">
                        <div className="contact-details-entry">
                            <p>First Name*</p>
                            <input className='contact-first-name-input' type="text" />
                        </div>

                        <div className="contact-details-entry">
                            <p>Last Name</p>
                            <input className='contact-last-name-input' type="text" />
                        </div>
                    </div>

                    <div className="contact-details">
                        <div className="contact-details-entry">
                            <p>Email*</p>
                            <input className='contact-email-input' type="text" />
                        </div>

                        <div className="contact-details-entry">
                            <p>Phone Number*</p>
                            <input className='contact-phone-input' type="text" />
                        </div>
                    </div>

                    <div className="contact-details">
                        <div className="contact-details-entry">
                            <p>Company*</p>
                            <input className='contact-company-input' type="text" />
                        </div>

                        <div className="contact-role">
                            <p>Your Role</p>
                            <select className='contact-role-input' name="contact-role" id="contact-role">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                            </select>
                        </div>
                    </div>

                    <div className="contact-message">
                        <p>Message*</p>
                        <input className='contact-message-input' type="text" />
                    </div>

                    <div className="contact-submit">
                        Submit
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BottomContact