import React from 'react'
import './bottomAbout.css'
import FadeInDivOnScroll from '../GeneralComponent/fadeInDivScroll'

function BottomAbout() {
    return (
        <div className="humnx-about">
            <div className="humnx-about-top">
                <div className="humnx-about-lists">

                    <FadeInDivOnScroll class='humnx-about-list-1-animation'>
                        <div className="humnx-about-lists-name">
                            <p>Company</p>
                            <ul>
                                <li>About</li>
                                <li>Services</li>
                                <li>Domains</li>
                                <li>Works</li>
                                <li>Careers</li>
                                <li>Contact</li>
                            </ul>
                        </div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-list-2-animation'>
                        <div className="humnx-about-lists-name">
                            <p>Services</p>
                            <ul>
                                <li>Experience Design</li>
                                <li>Brand Identity Design</li>
                                <li>Micro Animation</li>
                                <li>Graphic Design</li>
                                <li>Presenation Design</li>
                                <li>Digital Marketing</li>
                            </ul>
                        </div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-list-3-animation'>
                        <div className="humnx-about-lists-name">
                            <p>Company</p>
                            <ul>
                                <li>About us</li>
                                <li>Careers</li>
                                <li>Contact us</li>
                                <li>Lift Media</li>
                            </ul>
                        </div>
                    </FadeInDivOnScroll>
                </div>

                <div className="humnx-about-subscribe">
                    <div className="humnx-about-subscribe-head">Subscribe to our newsletter</div>
                    <div className="humnx-about-subscribe-input">
                        <input type="text" value="Email address" />
                        <div className="humnx-about-subscribe-arrowbutton"><img src="homepage_assets/images/arrow-right.png" alt="arrow button" /></div>
                    </div>
                    <div className="humnx-about-subscribe-desc">By submitting, you agree to receive important updates and insights via email.</div>

                </div>
            </div>

            <div className="humnx-about-hr"></div>

            <div className="humnx-about-bottom">
                <div className="humnx-about-bottom-left">
                    <FadeInDivOnScroll class='humnx-about-list-1-animation'>
                        <img className="humnx-about-logo" src='navbar_assets/tevah-navbar.png' alt='tevah' />
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-list-2-animation'>
                        <div className="humnx-about-privacy-cookies">
                            <span>Privacy Policy</span>
                            <span>Cookies</span>
                        </div>
                    </FadeInDivOnScroll>
                </div>

                <div className="humnx-about-bottom-right">
                    <FadeInDivOnScroll class='humnx-about-social-4-animation'>
                        <div className="humnx-about-social"><img src="homepage_assets/images/instagram.png" alt="" /></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-social-3-animation'>
                        <div className="humnx-about-social"><img src="homepage_assets/images/linkedin.png" alt="" /></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-social-2-animation'>
                        <div className="humnx-about-social"><img src="homepage_assets/images/facebook.png" alt="" /></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='humnx-about-social-1-animation'>
                        <div className="humnx-about-social"><img src="homepage_assets/images/x.png" alt="" /></div>
                    </FadeInDivOnScroll>
                </div>
            </div>
        </div>
    )
}

export default BottomAbout