import React from 'react'
import Navbar from '../Navbar/navbar'
import './Work.css'

function Work() {
    return (
        <>
            <div className="foreground">
                <Navbar selected={'work'}></Navbar>
            </div>
        </>
    )
}

export default Work