import React from 'react'
import './About.css'
import Navbar from '../Navbar/navbar'

function About() {

  return (
    <>
      <div className="foreground">
        <Navbar selected={'about'}></Navbar>
      </div>
    </>
  )
}

export default About