import React, { useState, useRef, useEffect } from "react";

const FadeInDivOnScroll = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const divRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Hide div when it leaves the viewport
                    }
                });
            },
            {
                rootMargin: "100px 0px 50px 0px",
                threshold: 0.01 // Trigger when 1% of the element is visible
            }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, []);

    return (
        <div
            ref={divRef}
            className={`fade-out ${isVisible ? `${props.class}` : ""}`}
        >
            {props.children}
        </div>
    );
};

export default FadeInDivOnScroll