import React, { useEffect, useRef, useState } from 'react'
import './Homepage.css'
import Projects from './Projects'
import Whatwedo from './Whatwedo'
import Navbar from '../Navbar/navbar'
import BottomContact from '../BottomContact/bottomContact'
import BottomAbout from '../BottomAbout/bottomAbout'
import FadeInDivOnScroll from '../GeneralComponent/fadeInDivScroll'

function Homepage() {

    const targetRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsScrolling(true);
            }
            else {
                setIsScrolling(false)
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [visibleText, setVisibleText] = useState(1); // Track which text is visible

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleText((prevText) => (prevText === 1 ? 2 : 1));
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>

            {/* ////////// INTRODUCTION ////////// */}


            <div className="background">
                <div className='background-img'></div>

                <div className="home-hidden-part">
                    <FadeInDivOnScroll class="home-hidden-part-left">
                        <div className="home-hidden-part-left">Digitize brilliance, Humanize impact</div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class="home-hidden-part-right">
                        <div className="home-hidden-part-right">
                            <div className="home-hidden-part-right-top">Experience design (XD) plays a crucial role in the success of digital products by ensuring they are user-centered, functional, and emotionally engaging.</div>

                            <div className="home-hidden-part-right-bottom">
                                <div className="home-hidden-part-lets-connect">Let's Connect</div>
                                <img src="homepage_assets/images/hidden-part-arrow-right-circle.png" alt="arrow right" />
                            </div>
                        </div>
                    </FadeInDivOnScroll>
                </div>
            </div>


            <div className="foreground">

                <div className="lights">
                    <img src="homepage_assets/images/light1.png" alt="" className="light1" />
                    <img src="homepage_assets/images/light2.png" alt="" className="light2" />
                </div>

                <Navbar targetRef={targetRef}></Navbar>

                <div className="intro">

                    <FadeInDivOnScroll class="intro-qoute">
                        <div className="intro-qoute">
                            <ul>
                                <li className={`${visibleText === 1 ? "intro-qoute-1" : "intro-qoute-1-fade-out"}`}>Leading the Way in <span className='bold-intro'>UI/UX</span></li>
                                <li className={`${visibleText === 2 ? "intro-qoute-2" : "intro-qoute-2-fade-out"}`}>Curating <span className='bold-intro'>Experiences</span> that Inspire and Transform!</li>
                            </ul>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class="intro-giff">
                        <img className='intro-giff' src="homepage_assets/gif/first.gif" alt='intro giff'></img>
                    </FadeInDivOnScroll>
                </div>

                <div className={isScrolling ? 'projects-to-rest-moved' : 'projects-to-rest'} >

                    <div className="moving-box">
                        <video autoplay='autoplay' muted loop class="autoplay-video">
                            <source src="homepage_assets/videos/moving_box_video.mp4" type="video/mp4" />
                        </video>
                    </div>


                    {/* ////////// PROJECTS ////////// */}


                    <ul className="projects-heading">
                        <li>
                            <FadeInDivOnScroll class='projects-qoute'>
                                <ul className='projects-qoute'>
                                    <li>Crafting impactful</li>
                                    <li>experinces through design</li>
                                </ul>
                            </FadeInDivOnScroll>
                        </li>
                        <li className='projects-right-arrow'><img src="homepage_assets/images/arrow-right-circle.png" alt="arrow" /></li>
                    </ul>

                    <ul className="projects-completed">
                        <li >
                            <FadeInDivOnScroll class='projects-completed-ul'>
                                <ul className='projects-completed-ul'>
                                    <li className='projects-completed-count'>10+</li>
                                    <li className='projects-completed-tag'>Projects Completed</li>
                                </ul>
                            </FadeInDivOnScroll>
                        </li>

                        <li className='projects-vertical-line'></li>

                        <FadeInDivOnScroll class='projects-explanation'>
                            <li className='projects-explanation'>we specialize in weaving captivating narratives through design. Dive into our portfolio and explore the stories we've brought to life through our innovative designs and creative solutions.</li>
                        </FadeInDivOnScroll>
                    </ul>

                    <div className="projects-demo">
                        <FadeInDivOnScroll class='project-x'>
                            <Projects source={"homepage_assets/images/project1.png"}></Projects>
                        </FadeInDivOnScroll>
                        <FadeInDivOnScroll class='project-y'>
                            <Projects source={"homepage_assets/images/project2.png"}></Projects>
                        </FadeInDivOnScroll>
                        <FadeInDivOnScroll class='project-x'>
                            <Projects source={"homepage_assets/images/project3.png"}></Projects>
                        </FadeInDivOnScroll>
                        <FadeInDivOnScroll class='project-y'>
                            <Projects source={"homepage_assets/images/project4.png"}></Projects>
                        </FadeInDivOnScroll>
                    </div>


                    {/* ////////// CLIENTS ////////// */}


                    <ul className="clients">
                        <li>
                            <ul>
                                <FadeInDivOnScroll class='clients-head'>
                                    <li className='clients-head'>Clients</li>
                                </FadeInDivOnScroll>
                                <FadeInDivOnScroll class='clients-body'>
                                    <li >Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.</li>
                                </FadeInDivOnScroll>
                            </ul>
                        </li>
                        <FadeInDivOnScroll class='clients-images'>
                            <li className='clients-images'>
                                <div class="client-faded-box-1"></div>
                                <li><img src="homepage_assets/images/clients-img.png" alt="clients homepage_assets/images" /></li>
                                <div class="client-faded-box-2"></div>
                            </li>
                        </FadeInDivOnScroll>
                    </ul>


                    {/* ////////// WHAT WE DO ////////// */}


                    <div className="what-we-do">
                        <FadeInDivOnScroll class='what-we-do-heading-animation'>
                            <img className="what-we-do-heading" src='homepage_assets/images/WHAT-WE-DO.png' alt='' />
                        </FadeInDivOnScroll>
                        <FadeInDivOnScroll class='what-we-do-qoute-animation'>
                            <div className="what-we-do-qoute">Delivering Tailored Digital Solutions</div>
                        </FadeInDivOnScroll>

                        <div className="our-services">Our Services</div>

                        <div className="what-we-do-services">

                            <Whatwedo textAnimation='text-animation-left' width={{ width: '65%' }} style={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to left, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1' }} head={"Branding & Visual Design"} desc={"Our branding and visual design services help bring your brand to life through thoughtful, compelling design. From logo creation to complete brand identity development, we craft visuals that resonate with your audience."}></Whatwedo>

                            <Whatwedo textAnimation='text-animation-center' width={{ width: '75%' }} style={{ borderStyle: 'solid', borderWidth: '0px 3px 3px 3px', borderImage: 'linear-gradient(to top, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1' }} head={"User Interface Design (UI)"} desc={"We specialize in crafting intuitive and visually engaging user interfaces that enhance the overall digital experience. Our Ul design services focus on creating interfaces that are not only aesthetically appealing but also easy to navigate."}></Whatwedo>

                            <Whatwedo textAnimation='text-animation-right' width={{ width: '80%' }} style={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to right, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1' }} head={"User Experience Design (UX)"} desc={"Prioritize the needs and emotions of your users, ensuring an intuitive and enjoyable interaction with your product. We take a user-centered approach, combining research, wireframing, and prototyping to create seamless journeys that make your digital platforms easy to use."}></Whatwedo>

                            <Whatwedo textAnimation='text-animation-left' width={{ width: '60%' }} head={"App Development"} desc={"Our app development services transform your ideas into fully functional, user- friendly mobile applications. Whether for IOS or Android, we build custom apps designed to meet your business goals and provide exceptional user experiences."}></Whatwedo>

                            <Whatwedo textAnimation='text-animation-center' width={{ width: '88%' }} style={{ borderStyle: 'solid', borderWidth: '0px 3px 0px 3px', borderImage: 'linear-gradient(to bottom, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1' }} head={"Website & E-commerce"} desc={"We offer comprehensive website and e- commerce development services that bring your digital presence to life. Whether you need a corporate website or a full- fledged online store, we build responsive, user-friendly, and visually appealing platforms tailored to your brand's needs."}></Whatwedo>

                            <Whatwedo textAnimation='text-animation-right' width={{ width: '60%' }} head={"Digital Marketing"} desc={"Designed to boost your brand's online presence and drive measurable results. We create tailored strategies that include search engine optimization (SEO), social media marketing, email campaigns, and paid advertising to help you reach your target audience effectively."}></Whatwedo>
                        </div>

                        <div className="achieve-top-lights">
                            <img src="homepage_assets/images/light1.png" alt="" className="achieve-light1" />
                            <img src="homepage_assets/images/light2.png" alt="" className="achieve-light2" />
                        </div>

                    </div>


                    {/* ////////// ACHIEVEMENTS ////////// */}


                    <div className="achievement-box">
                        <FadeInDivOnScroll class='achievement-ball-animation'>
                            <div className="achieve-ball">
                                <img src="homepage_assets/gif/achievement-giff.gif" alt="ball" />
                            </div>
                        </FadeInDivOnScroll >
                        <ul>
                            <FadeInDivOnScroll class='achievement-head-animation'>
                                <li className='achieve-head'>We are Designers, That Evoke Emotions</li>
                            </FadeInDivOnScroll >
                            <FadeInDivOnScroll class='achievement-head-animation'>
                                <li className='achieve-body'>Our journey is defined by the successful completion of numerous projects and long-lasting relationships with clients across diverse industries. Each project reflects our dedication to quality, innovation, and results-driven solutions. These milestones represent not just numbers but the trust and satisfaction of our clients, along with the growth we've achieved together.</li>
                            </FadeInDivOnScroll >
                            <FadeInDivOnScroll class='achievement-badge-animation'>
                                <li className="achieve-badges">
                                    <ul>
                                        <li>
                                            <span className='achieve-value'>10+</span>
                                            <span className='achieve-name'>Projects Completed</span>
                                        </li>
                                        <li>
                                            <span className='achieve-value'>05</span>
                                            <span className='achieve-name'>Creative Minds</span>
                                        </li>
                                        <li>
                                            <span className='achieve-value'>03+</span>
                                            <span className='achieve-name'>Years of Experience</span>
                                        </li>
                                        <li>
                                            <span className='achieve-value'>10+</span>
                                            <span className='achieve-name'>Clients</span>
                                        </li>
                                    </ul>
                                </li>
                            </FadeInDivOnScroll >
                        </ul>
                    </div>


                    {/* ////////// CONTACT & ABOUT ////////// */}


                    <BottomContact></BottomContact>

                    <BottomAbout ></BottomAbout>

                </div>
            </div>
        </>
    )
}

export default Homepage