
import './App.css';
import Homepage from './Homepage/Homepage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Services from './Services/Services';
import Work from './Work/Work';
import About from './About/About';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/work" element={<Work />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
