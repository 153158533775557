import React from 'react'
import './Whatwedo.css'
import FadeInDivOnScroll from '../GeneralComponent/fadeInDivScroll'

function Whatwedo(props) {
    return (
        <div className='what-we-do-box' style={props.style}>
            <div className='what-we-do-background' />
            <FadeInDivOnScroll class={props.textAnimation}>
                <div className="what-we-do-foreground">
                    <div className="what-we-do-text">
                        <div className='heading' style={props.width}>{props.head}</div>
                        <div className="desc">{props.desc}</div>
                    </div>
                    <div className="learn-more">
                        <span>Learn More</span>
                        <img src="homepage_assets/images/arrow-right.png" alt="arrow right" />
                    </div>
                </div>
            </FadeInDivOnScroll>
        </div>
    )
}

export default Whatwedo
