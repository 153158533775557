import React from 'react'
import './Projects.css'

function Projects(props) {
    return (
        <div className='project-x' >
            <div className="project-x-most-upper-layer">

                <div className="project-x-most-upper-layer-left">
                    <div className="project-x-upper-layer-head">Capable Website</div>
                    <div className="project-x-upper-layer-body">Lorem ipsum is placeholder text commonly used.</div>
                </div>

                <div className="project-x-most-upper-layer-right">
                    <img src="homepage_assets/images/inclined-arrow.png" alt="inclined arrow" />
                </div>
            </div>

            <img src="homepage_assets/images/project-shadow.png" alt="shadow" className="project-x-upper-layer" />
            <img src={props.source} alt="project" />
        </div >
    )
}

export default Projects